/**
 * @author Beka Chkhaidze
 * @sumamry attach event listener to target and deattach after unmount.
 */

import { onMounted, onUnmounted } from "vue";

export const useEventListener = (
    target: EventTarget,
    event: keyof WindowEventMap,
    callback: EventListenerOrEventListenerObject | any
): void => {
    onMounted(() => target?.addEventListener(event, callback));
    onUnmounted(() => target.removeEventListener(event, callback));
};
